import React, { useState, useRef, useEffect } from "react";
import styles from "./input-field.module.scss";
import PropTypes from "prop-types";
import "../../../../src/theme/spacing.scss";
import { useTranslation } from "react-i18next";
import TooltipMessage from "../../tooltip-message";

const InputField = ({
  name,
  icon,
  inputadornment,
  onIconClick,
  inputContent,
  inputContentAdornment,
  isDisabled,
  label,
  onChange,
  value,
  errorMessage,
  isLabelOutsideInput,
  tooltipIcon,
  tooltipMessage,
  placeholder,
  minIbanLength,
  maxIbanLength,
  onBlur = (e) => {},
  ...rest
}) => {
  const { i18n, t } = useTranslation("common");
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [direction, setDirection] = useState("rtl");

  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
    setIsActive(true);
  };

  const handleBlur = (e) => {
    onBlur(e);
    setIsFocused(false);
    if (!value) {
      setIsActive(false);
    }
  };

  const handleClickLabel = () => {
    if (!isActive && !isDisabled) {
      setIsActive(true);
      setIsFocused(true);
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    if (!value && !isFocused) {
      setIsActive(false);
    }
  }, [value]);

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setDirection(dir);
  }, [i18n, i18n.language]);
  const getDirection = () =>
    direction === "ltr"
      ? {
          left: inputadornment === "start" ? "var(--large-spacing)" : "auto",
          right: inputadornment === "end" ? "var(--large-spacing)" : "auto",
        }
      : {
          left: inputadornment === "end" ? "var(--large-spacing)" : "auto",
          right: inputadornment === "start" ? "var(--large-spacing)" : "auto",
        };

  return (
    <div className={styles.container}>
      {isLabelOutsideInput && (
        <label className={styles.fixedLabel} onClick={handleClickLabel}>
          {label}
        </label>
      )}
      <div
        className={`${styles.inputContainer} ${
          errorMessage ? styles.error : ""
        }`}
      >
        {tooltipIcon && (
          <div className={styles.tooltipIcon}>
            <TooltipMessage message={tooltipMessage} icon={tooltipIcon} />
          </div>
        )}
        {icon && (
          <img
            src={icon}
            alt="icon"
            className={styles.inputIcon}
            onClick={onIconClick}
            style={getDirection()}
            data-testid={`tooltip${name}`}
          />
        )}
        {inputContent && (
          <span
            className={styles.inputContent}
            style={{
              left:
                inputContentAdornment === "start"
                  ? "var(--large-spacing)"
                  : "auto",
              right:
                inputContentAdornment === "end"
                  ? "var(--large-spacing)"
                  : "auto",
            }}
          >
            {inputContent}
          </span>
        )}

        {!isLabelOutsideInput && (
          <label
            className={`${styles.floatingLabel} ${
              isActive || value || inputContent ? styles.active : ""
            } ${isFocused && isActive ? styles.focused : ""} ${
              errorMessage ? styles.error : ""
            } ${isDisabled ? styles.disabled : ""}
            ${!value && isDisabled ? styles.emptyDisabled : ""}`}
            onClick={handleClickLabel}
            style={{
              left: ` ${direction === "ltr" ? (isActive || isFocused || inputContent ? "var(--medium2-spacing)" : inputadornment === "start" ? "35px" : "var(--medium2-spacing)") : "auto"}`,
              right: ` ${direction === "rtl" ? (isActive || isFocused || inputContent ? "var(--medium2-spacing)" : inputadornment === "start" ? "35px" : "var(--medium2-spacing)") : "auto"}`,
            }}
          >
            {label}
          </label>
        )}

        <input
          className={`${styles.inputField} ${isDisabled ? styles.disabled : ""}`}
          onFocus={handleFocus}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          disabled={isDisabled}
          value={value}
          placeholder={placeholder}
          ref={inputRef}
          aria-label={name}
          {...rest}
          style={
            direction === "ltr"
              ? {
                  paddingLeft:
                    inputadornment === "start"
                      ? "var(--double-extra-large-spacing)"
                      : inputContentAdornment === "start"
                        ? "var(--extra-extra-large-spacing)"
                        : "var(--large-spacing)",

                  paddingRight:
                    inputContentAdornment === "end"
                      ? "var(--extra-extra-large-spacing)"
                      : "var(--double-extra-large-spacing)",
                }
              : {
                  paddingLeft:
                    inputadornment === "end"
                      ? "var(--double-extra-large-spacing)"
                      : inputContentAdornment === "start"
                        ? "var(--extra-extra-large-spacing)"
                        : "var(--large-spacing)",

                  paddingRight:
                    inputContentAdornment === "end"
                      ? "var(--extra-extra-large-spacing)"
                      : "var(--large-spacing)",
                }
          }
        />
      </div>

      {errorMessage && (
        <span className={styles.errorMessage}>
          {t(errorMessage, { min: minIbanLength, max: maxIbanLength })}
        </span>
      )}
    </div>
  );
};

InputField.propTypes = {
  icon: PropTypes.string,
  inputadornment: PropTypes.string,
  inputContent: PropTypes.string,
  inputContentAdornment: PropTypes.string,
  onIconClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  isLabelOutsideInput: PropTypes.bool,
  tooltipIcon: PropTypes.string,
  tooltipMessage: PropTypes.string,
  placeholder: PropTypes.string,
  minIbanLength: PropTypes.number,
  maxIbanLength: PropTypes.number,
};

export default InputField;

import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main-layout";
import clockIcon from "../../assets/icons/clock-icon.svg";
import styles from "./journey-timeline.module.scss";
import TimeLine from "../../widgets/time-line";
import {
  COMPLETE_PROFILE_ID,
  IDENTITY_VERIFICATION_ID,
  REVIEW_SIGN_ID,
  timeLineConfig,
} from "./config";
import PrimaryButton from "../../widgets/buttons/primary-button";
import { areAllStepsDone, isStepDoneById } from "../../helpers/general-helpers";
import { useNavigate } from "react-router-dom";
import {
  getCustomer,
  getCustomerOrder,
  postCustomerOrder,
  putCustomerOrderBrokerage,
} from "../../general-services.proxy";
import {
  identityVerificationRoute,
  kycSummaryStepRoute,
  personalInfoStepRoute,
  reviewAndSignRoute,
} from "../../routes/routes.const";
import { useTranslation } from "react-i18next";
import {
  activeCustomerStatusEnums,
  brokerageEums,
  creatingOrderServicesEnums,
  customerStatusEnums,
  entityEnums,
} from "../../enums/general";
import InternationalBrokerageType from "./components/international-type-field";
import BrokerageType from "./components/brokerage-type-field";
import {
  setIsAllStepsDone,
  setKycSubmitted,
} from "../../store/slices/general.slice";
import { useDispatch } from "react-redux";
import CircularLoader from "../../widgets/circular-loader";
import { getSavedInternationalType, getSavedProduct } from "../../utils";

const JourneyTimeline = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [journeyTimeline, setJourneyTimeLine] = useState(timeLineConfig);
  const [orderId, setOrderId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isKycSubmitted, setIsKycSubmitted] = useState(false);
  const [isContractSigned, setIsContractSigned] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [brokerageTypePrevious, setBrokerageTypePrevious] = useState();
  const [
    internationalBrokerageTypePrevious,
    setInternationalBrokerageTypePrevious,
  ] = useState();
  const [orderBrokerageId, setOrderBrokerageId] = useState("");
  const [isReturnedRequest, setIsReturnedRequest] = useState("");

  const [brokerageType, setBrokerageType] = useState(getSavedProduct());
  const [internationalBrokerageType, setInternationalBrokerageType] = useState(
    getSavedInternationalType()
  );
  const isAllStepsDone = areAllStepsDone(journeyTimeline);
  const titlePage = !isAllStepsDone
    ? t("openYourAccount")
    : t("applicationUnderReview");
  const subTitlePage = !isAllStepsDone
    ? t("beginTradingJourneyDescription")
    : t("reviewMessage");

  const navigateToNextPage = (availableOrderId) => {
    if (isStepDoneById(journeyTimeline, COMPLETE_PROFILE_ID)) {
      navigate(
        {
          pathname: reviewAndSignRoute,
        },
        {
          state: {
            orderId: availableOrderId,
          },
        }
      );
    } else if (isStepDoneById(journeyTimeline, IDENTITY_VERIFICATION_ID)) {
      navigate({
        pathname: isReturnedRequest
          ? kycSummaryStepRoute
          : personalInfoStepRoute,
      });
    } else {
      navigate(
        {
          pathname: identityVerificationRoute,
        },
        {
          state: {
            orderId: availableOrderId,
          },
        }
      );
    }
  };

  const createOrder = async () => {
    try {
      const payload =
        brokerageType === brokerageEums.REGIONAL_BROKERAGE_RBU
          ? {
              bank_information: {
                bank_name: "",
                iban_number: "",
              },
              is_share_transfer_enabled: false,
            }
          : {
              international_account_information: {
                brokerage_account_type: internationalBrokerageType,
              },
              bank_information: {
                bank_name: "",
                iban_number: "",
              },
            };

      let createCustomerOrderPayload = null;
      if (!customer.isNbkcClient) {
        createCustomerOrderPayload = {
          services: [
            creatingOrderServicesEnums.KYC_ONBOARDING,
            creatingOrderServicesEnums.BROKERAGE,
          ],
          product_type: brokerageType,
          entity: entityEnums.NBK_KUWAIT,
          payload: payload,
        };
      }

      if (
        customer.isNbkcClient &&
        activeCustomerStatusEnums.includes(customer.status)
      ) {
        createCustomerOrderPayload = {
          services: [creatingOrderServicesEnums.BROKERAGE],
          product_type: brokerageType,
          entity: entityEnums.NBK_KUWAIT,
          payload: payload,
        };
      }

      const { order_id: availableOrderId } = await postCustomerOrder(
        createCustomerOrderPayload
      );
      return availableOrderId;
    } catch (exception) {
      throw new Error(exception);
    } finally {
      setIsSaving(false);
    }
  };

  const updateProduct = async () => {
    try {
      const payload =
        brokerageType === brokerageEums.REGIONAL_BROKERAGE_RBU
          ? {
              bank_information: {
                bank_name: "",
                iban_number: "",
              },
              is_share_transfer_enabled: false,
            }
          : {
              international_account_information: {
                brokerage_account_type: internationalBrokerageType,
              },
              bank_information: {
                bank_name: "",
                iban_number: "",
              },
            };
      const updateCustomerOrderPayload = {
        product_type: brokerageType,
        entity: entityEnums.NBK_KUWAIT,
        payload: payload,
      };
      await putCustomerOrderBrokerage(
        orderId,
        orderBrokerageId,
        updateCustomerOrderPayload
      );
    } catch (exception) {
      throw new Error(exception);
    } finally {
      setIsSaving(false);
    }
  };

  const onClick = async () => {
    try {
      setIsSaving(true);
      let availableOrderId = orderId;
      if (
        brokerageType !== brokerageTypePrevious ||
        internationalBrokerageType !== internationalBrokerageTypePrevious
      ) {
        if (orderId && orderBrokerageId) {
          await updateProduct();
        } else {
          availableOrderId = await createOrder();
        }
        navigateToNextPage(availableOrderId);
        return;
      }
      navigateToNextPage(availableOrderId);
    } catch (exception) {
      console.error(exception);
    }
  };

  const updateJourneyTimeLine = async () => {
    try {
      setIsLoading(true);
      const {
        isCivilIdBackUploaded,
        isCivilIdFrontUploaded,
        isCivilIdSelfieUploaded,
        isKycSubmitted,
        productType,
        brokerageAccountType,
        orderBrokerageId,
        isContractSigned,
        isReturnedRequest,
      } = await getCustomerOrder(orderId);
      setIsKycSubmitted(isKycSubmitted);
      setIsContractSigned(isContractSigned);
      dispatch(setKycSubmitted(isKycSubmitted));
      setBrokerageTypePrevious(productType);
      if (productType) {
        setBrokerageType(productType);
      }
      setInternationalBrokerageTypePrevious(brokerageAccountType);
      setInternationalBrokerageType(brokerageAccountType);
      setOrderBrokerageId(orderBrokerageId);
      setIsReturnedRequest(isReturnedRequest);

      if (
        customer.isNbkcClient ||
        customer.status === customerStatusEnums.ADDING_SERVICE_ENABLED
      ) {
        setJourneyTimeLine((prevTimeline) =>
          prevTimeline.map((item) =>
            item.id === IDENTITY_VERIFICATION_ID ||
            item.id === COMPLETE_PROFILE_ID
              ? { ...item, isDone: true }
              : item
          )
        );
      }

      if (
        isCivilIdBackUploaded &&
        isCivilIdFrontUploaded &&
        isCivilIdSelfieUploaded
      ) {
        setJourneyTimeLine((prevTimeline) =>
          prevTimeline.map((item) =>
            item.id === IDENTITY_VERIFICATION_ID
              ? { ...item, isDone: true }
              : item
          )
        );
      }

      if (isKycSubmitted) {
        setJourneyTimeLine((prevTimeline) =>
          prevTimeline.map((item) =>
            item.id === COMPLETE_PROFILE_ID ? { ...item, isDone: true } : item
          )
        );
      }

      if (isContractSigned) {
        setJourneyTimeLine((prevTimeline) =>
          prevTimeline.map((item) =>
            item.id === REVIEW_SIGN_ID ? { ...item, isDone: true } : item
          )
        );
      }
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomerData = async () => {
    try {
      setIsLoading(true);
      const customer = await getCustomer();
      setCustomer(customer);
      if (
        customer.isNbkcClient ||
        customer.status === customerStatusEnums.ADDING_SERVICE_ENABLED
      ) {
        setJourneyTimeLine((prevTimeline) =>
          prevTimeline.map((item) =>
            item.id === IDENTITY_VERIFICATION_ID ||
            item.id === COMPLETE_PROFILE_ID
              ? { ...item, isDone: true }
              : item
          )
        );
      }
      setOrderId(customer.orderId);
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  useEffect(() => {
    if (orderId) {
      updateJourneyTimeLine();
    }
  }, [orderId]);

  useEffect(() => {
    if (isKycSubmitted && isContractSigned) {
      dispatch(setIsAllStepsDone(isAllStepsDone));
    }
  }, [isKycSubmitted, isContractSigned]);

  const renderFooter = () => {
    if (isAllStepsDone) {
      return <></>;
    }

    const checkIfDisabled = () => {
      if (isSaving || !brokerageType) {
        return true;
      }

      if (
        brokerageType === brokerageEums.INTERNATIONAL_BROKERAGE &&
        !internationalBrokerageType
      ) {
        return true;
      }
      return false;
    };

    return (
      <PrimaryButton
        onClick={onClick}
        disabled={checkIfDisabled()}
        data-testid="start"
      >
        {t("start")}
      </PrimaryButton>
    );
  };

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <MainLayout
        title={titlePage}
        subTitle={subTitlePage}
        footer={renderFooter}
        isAllStepsDone={isAllStepsDone}
      >
        <div>
          <div className={styles.container}>
            <img src={clockIcon} alt="icon" />
            <span className={styles.estimatedTime}>{t("estimatedTime")}</span>
          </div>
          <div className={styles.timeLineContainer}>
            <div className={styles.fieldsContainer}>
              <span className={styles.labelBrokerage}>
                {t("labelBrokerage")}
              </span>

              <BrokerageType
                data={brokerageType}
                setData={setBrokerageType}
                isDisabled={
                  !!isKycSubmitted || !!isContractSigned || !!isReturnedRequest
                }
              />
              {brokerageType === brokerageEums.INTERNATIONAL_BROKERAGE ? (
                <InternationalBrokerageType
                  data={internationalBrokerageType}
                  setData={setInternationalBrokerageType}
                  isDisabled={
                    !!isKycSubmitted ||
                    !!isContractSigned ||
                    !!isReturnedRequest
                  }
                />
              ) : (
                <></>
              )}
            </div>

            <TimeLine steps={journeyTimeline} />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default JourneyTimeline;

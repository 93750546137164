import React, { useEffect, useState } from "react";
import styles from "./drawer.module.scss";
import closeIcon from "../../assets/icons/close-modal.svg";
import MenuItem from "../../components/menu-item";
import rightArrowIcon from "../../assets/icons/righ-arrow.svg";
import logoutIcon from "../../assets/icons/logout-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  resetStateOnLogout,
  setKycFields,
  setCountries,
} from "../../store/slices/general.slice";
import { getLanguage, setLanguage } from "../../utils";
import { isWebRenewalEnv, languageOptions } from "../../config";
import { useTranslation } from "react-i18next";
import { languageEnum } from "../../enums/language-enum";
import {
  fullDisclosureUrlAr,
  fullDisclosureUrlEn,
  privacyPolicyUrlAr,
  privacyPolicyUrlEn,
} from "../../config/outside-urls";
import { useNavigate } from "react-router-dom";
import {
  civildIDetailsRenewalRoute,
  contactSupportRoute,
  landingRoute,
} from "../../routes/routes.const";
import { getToken } from "../../utils/auth";
import { getCustomer, putLanguage } from "../../general-services.proxy";

const Drawer = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n, t } = useTranslation("common");
  const [selectedLanguage, setSelectedLanguage] = useState(
    getLanguage() || languageEnum.english
  );
  const { kycFieldsAr, kycFieldsEn, countriesAr, countriesEn } = useSelector(
    (state) => state.general
  );

  const token = getToken();

  const getLanguageLabel = () =>
    languageOptions.find((language) => language.value === selectedLanguage)
      .label;

  const updateLanguage = async (language) => {
    if (!token) {
      return;
    }

    try {
      await putLanguage(language);
    } catch (exception) {
      console.error(exception);
    }
  };

  const handleFirsMenuClick = () => {
    if (selectedLanguage === languageEnum.english) {
      setSelectedLanguage(languageEnum.arabic);
      setLanguage(languageEnum.arabic);
      i18n.changeLanguage(languageEnum.arabic);
      dispatch(setKycFields(kycFieldsAr));
      dispatch(setCountries(countriesAr));
      updateLanguage(languageEnum.arabic);
    } else if (selectedLanguage === languageEnum.arabic) {
      setSelectedLanguage(languageEnum.english);
      setLanguage(languageEnum.english);
      i18n.changeLanguage(languageEnum.english);
      dispatch(setKycFields(kycFieldsEn));
      dispatch(setCountries(countriesEn));
      updateLanguage(languageEnum.english);
    }
  };

  const handleSecondMenuClick = () => {
    if (selectedLanguage === languageEnum.english) {
      window.open(privacyPolicyUrlEn);
    } else {
      window.open(privacyPolicyUrlAr);
    }
  };

  const handleThirdMenuClick = () => {
    if (selectedLanguage === languageEnum.english) {
      window.open(fullDisclosureUrlEn);
    } else {
      window.open(fullDisclosureUrlAr);
    }
  };

  const handleLogoutClick = () => {
    sessionStorage.clear();
    dispatch(resetStateOnLogout());
    if (isWebRenewalEnv) {
      navigate(civildIDetailsRenewalRoute);
    } else {
      navigate(landingRoute);
    }
    closeModal();
  };

  const handleContactSupportPage = () => {
    navigate(contactSupportRoute);
    closeModal();
  };

  const firstMenuItemIcon = () => (
    <div className={styles.languageBox}>{getLanguageLabel()}</div>
  );

  const rightArrowMenuItemIcon = () => (
    <img
      className={styles.iconStyle}
      src={rightArrowIcon}
      alt={"right-arrow"}
    />
  );
  const logoutMenuItemIcoj = () => (
    <div className={styles.logoutIconContainer}>
      <img className={styles.logoutIconStyle} src={logoutIcon} alt={"logout"} />
    </div>
  );
  const fetchCustomerData = async () => {
    try {
      const { language } = await getCustomer();

      if (language) {
        i18n.changeLanguage(language);
        setLanguage(language);
        setSelectedLanguage(language);
      }
    } catch (exception) {
      console.error(exception);
    }
  };

  useEffect(() => {
    if (token) {
      fetchCustomerData();
    }
  }, [token]);

  if (!isOpen) {
    return;
  }
  return (
    <div>
      <div className={styles.modalOverlay} onClick={closeModal}>
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={styles.closeIcon}
            onClick={() => {
              closeModal();
            }}
            data-testid="close"
          >
            <img src={closeIcon} alt={"close"} />
          </div>
          <span className={styles.modalTitle} data-testid="menu-title">
            {t("menu")}
          </span>
          <div className={styles.menuList}>
            <MenuItem
              optionLabel={t("contactSupport")}
              optionIcon={rightArrowMenuItemIcon}
              handleClick={handleContactSupportPage}
              testId="contact-support-page"
            />
            <MenuItem
              optionLabel={t("changeLanguage")}
              optionIcon={firstMenuItemIcon}
              handleClick={handleFirsMenuClick}
              testId="change-language-menu-item"
            />
            <MenuItem
              optionLabel={t("privacyPolicy")}
              optionIcon={rightArrowMenuItemIcon}
              handleClick={handleSecondMenuClick}
              testId="privacy-policy-menu-item"
            />
            {!isWebRenewalEnv && (
              <MenuItem
                optionLabel={t("fullDisclosure")}
                optionIcon={rightArrowMenuItemIcon}
                handleClick={handleThirdMenuClick}
                testId="full-disclosure-menu-item"
              />
            )}

            {token && (
              <MenuItem
                optionLabel={t("logout")}
                optionIcon={logoutMenuItemIcoj}
                handleClick={handleLogoutClick}
                testId="logout-menu-item"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;

import React from "react";
import successIcon from "../../../assets/icons/sucess-icon.svg";
import { useTranslation } from "react-i18next";
import {
  emailAddressDetailsRenewalRoute,
  journeyTimeLineRenewalRoute,
} from "../../../routes/routes.const";
import SuccessRenewalPage from "../../../components/success-component/renewal";

const OTPSuccessRenewal = () => {
  const { t } = useTranslation("common");
  return (
    <SuccessRenewalPage
      icon={successIcon}
      title={t("successOTP")}
      subTitle={t("verifiedMessage")}
      emailConfiramtionRoute={emailAddressDetailsRenewalRoute}
      journeyTimelineSuccessRoute={journeyTimeLineRenewalRoute}
    />
  );
};

export default OTPSuccessRenewal;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Accordion from "../../widgets/accordion";
import MainLayout from "../../layouts/main-layout";
import {
  personalInfoStepRoute,
  contactInfoStepRoute,
  employmentInfoStepRoute,
  additionalInfoStepRoute,
  incomeAndWealthInfoStepRoute,
  journeyTimeLineRoute,
} from "../../routes/routes.const";
import {
  getPersonalInfoStepDetails,
  getContactInfoStepDetails,
  getEmploymentStepDetails,
  getAdditionalInfoStepDetails,
  getIncomeAndWealthTabDetails,
} from "../../helpers/summary-step-helper";
import PrimaryButton from "../../widgets/buttons/primary-button";
import SkeletonLoader from "../../widgets/fields-skeleton";
import {
  getKycData,
  getCustomer,
  getCustomerOrder,
  putCustomerOrder,
} from "../../general-services.proxy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { languageEnum } from "../../enums/language-enum";
import styles from "./kyc-summary-step.module.scss";
import InfoBox from "../../components/info-box";

const KycSummaryStep = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const [language, setLanguage] = useState(languageEnum.english);
  const [isLoading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState("");

  const [stepData, setStepData] = useState({
    personalInfo: {
      data: {},
      routeName: personalInfoStepRoute,
      title: "personalInfoStepTitle",
    },
    contactInfo: {
      data: {},
      routeName: contactInfoStepRoute,
      title: "contactInformation",
    },
    employmentInfo: {
      data: {},
      routeName: employmentInfoStepRoute,
      title: "EmploymentInfoStepTitle",
    },
    incomeAndWealthInfo: {
      data: {},
      routeName: incomeAndWealthInfoStepRoute,
      title: "incomeAndWealthStepTitle",
    },
    additionalInfo: {
      data: {},
      routeName: additionalInfoStepRoute,
      title: "additionalInfo",
    },
  });
  const {
    countries,
    kycFields: kycFieldsOptions,
    cities,
  } = useSelector((state) => state.general);

  const {
    yes_no: yesOrNo = {},
    employment_status: employmentStatuses = {},
    employer_type: employerTypes = {},
    private_business_industry: privateSectorIndustry = {},
    government_entity_type: governmentEntityTypes = {},
    employment_sector: employmentSectors = {},
    last_employment_status: lastEmploymentStatuses = {},
    assets_value: assetsValues = {},
    transactions_value_past_two_years: transactionsValuesPastTwoYears = {},
    financial_sector_years_experience: financialSectorYearsExperiences = {},
    account_purpose: accountPurpose = {},
    income_annual_v2: incomeAnual = {},
    investment_reason: investmentReasons = {},
    investment_level: investmentLevels = {},
    risk_tolerance_level: riskToleranceLevels = {},
    bank_names: bankNames = {},
    private_business_industry: privateBusinessIndustries = {},
    private_business_sector: privateBusinessSectors = {},
    political_position_role: positions = {},
    investment_years_experience: investmentYearsExperience = {},
    employment_entity_name: employmentEntityNames = {},
    relationship = {},
    gender: genderOptions = {},
    income_source_v2: annualIncomeSource = {},
    wealth_source: wealthSource = {},
    relationship_status: relationshipStatus = {},
  } = kycFieldsOptions;

  const updateKycToDone = async () => {
    try {
      setLoading(true);
      await putCustomerOrder(orderId);
      navigate(journeyTimeLineRoute, {
        replace: true,
      });
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const renderFooter = () => (
    <PrimaryButton
      onClick={() => {
        updateKycToDone();
      }}
      disabled={isLoading}
      data-testid={"submitButton"}
    >
      {t("submitButtonLabel")}
    </PrimaryButton>
  );
  const getStepsDetails = (kycData) => {
    const personalInfoDetails = getPersonalInfoStepDetails(
      kycData,
      countries,
      genderOptions,
      relationshipStatus
    );
    const contactInfoDetails = getContactInfoStepDetails(
      kycData,
      countries,
      cities,
      language
    );
    const employmentInfoDetails = getEmploymentStepDetails(
      kycData,
      employmentStatuses,
      employerTypes,
      privateSectorIndustry,
      governmentEntityTypes,
      employmentSectors,
      lastEmploymentStatuses,
      employmentEntityNames,
      yesOrNo
    );
    const additionalInfoDetails = getAdditionalInfoStepDetails(
      kycData,
      positions,
      relationship,
      countries,
      yesOrNo
    );
    const incomeAndWealthInfoDetails = getIncomeAndWealthTabDetails(
      kycData,
      accountPurpose,
      incomeAnual,
      assetsValues,
      transactionsValuesPastTwoYears,
      financialSectorYearsExperiences,
      investmentReasons,
      investmentLevels,
      riskToleranceLevels,
      bankNames,
      privateBusinessIndustries,
      privateBusinessSectors,
      investmentYearsExperience,
      annualIncomeSource,
      wealthSource,
      yesOrNo
    );

    setStepData((prevState) => ({
      ...prevState,
      personalInfo: {
        ...prevState.personalInfo,
        data: personalInfoDetails,
      },
      contactInfo: {
        ...prevState.contactInfo,
        data: contactInfoDetails,
      },
      employmentInfo: {
        ...prevState.employmentInfo,
        data: employmentInfoDetails,
      },
      additionalInfo: {
        ...prevState.additionalInfo,
        data: additionalInfoDetails,
      },
      incomeAndWealthInfo: {
        ...prevState.incomeAndWealthInfo,
        data: incomeAndWealthInfoDetails,
      },
    }));
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId } = await getCustomer();
      setOrderId(orderId);
      const availableOrder = await getCustomerOrder(orderId);
      setOrder(availableOrder);
      const kycData = await getKycData(orderId, orderKycId);
      getStepsDetails(kycData);
      setLoading(false);
    } catch (exception) {
      console.error(exception);
    }
  };
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n, i18n.language]);

  useEffect(() => {
    if (Object.entries(kycFieldsOptions).length && language && countries) {
      fetchKycData();
    }
  }, [language, kycFieldsOptions, countries]);

  return (
    <MainLayout title={t("summaryStepTitle")} footer={renderFooter}>
      {isLoading ? (
        <SkeletonLoader numberOfFields={5} />
      ) : (
        <div className={styles.mainContainer}>
          {order.isReturnedRequest ? (
            <InfoBox
              title={t("attentionRequired")}
              subTitle={order.returnDescription}
            />
          ) : (
            <></>
          )}

          {Object.entries(stepData).map(([key, step]) => (
            <Accordion
              key={key}
              accordionData={step.data}
              accordionTitle={t(`${step.title}`)}
              routeName={step.routeName}
              testId={step.title}
            />
          ))}
        </div>
      )}
    </MainLayout>
  );
};

export default KycSummaryStep;

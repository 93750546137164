import React from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { capitalizeWords } from "../../../utils";

const MotherFirstName = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.MOTHER_FIRST_NAME;
  const motherNameKey = KycKeys.MOTHER_FIRST_NAME;

  const {
    [fieldKey]: { value, error },
    [motherNameKey]: { value: motherNameValue },
  } = data;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateNames(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  const handleBlur = ({ target: { value } }) => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        value: capitalizeWords(value),
      },
    }));
  };

  return (
    <InputField
      name="motherFirstNameField"
      label={translator("motherFirstName")}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value || motherNameValue}
      errorMessage={error}
    />
  );
};

MotherFirstName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default MotherFirstName;

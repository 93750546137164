import React from "react";
import styles from "./kyc-step-footer.module.scss";
import PropTypes from "prop-types";
import PrimaryButton from "../../widgets/buttons/primary-button";
import SecondaryButton from "../../widgets/buttons/renewal/secondary-button";

const KycStepFooter = ({
  firstButtonTitle,
  firstButtonAction,
  secondButtonTitle,
  secondButtonAction,
  firstButtonDisabled,
  secondButtonDisabled,
}) => {
  return (
    <div className={styles.footerContainer}>
      <PrimaryButton
        onClick={firstButtonAction}
        disabled={firstButtonDisabled}
        data-testid={firstButtonTitle}
      >
        {firstButtonTitle}
      </PrimaryButton>

      <SecondaryButton
        onClick={secondButtonAction}
        disabled={secondButtonDisabled}
        data-testid={secondButtonTitle}
      >
        {secondButtonTitle}
      </SecondaryButton>
    </div>
  );
};

KycStepFooter.prototype = {
  firstButtonTitle: PropTypes.string.isRequired,
  firstButtonAction: PropTypes.func.isRequired,
  secondButtonTitle: PropTypes.string.isRequired,
  secondButtonAction: PropTypes.func.isRequired,
  firstButtonDisabled: PropTypes.bool.isRequired,
  secondButtonDisabled: PropTypes.bool.isRequired,
};
export default KycStepFooter;

import React, { useEffect } from "react";
import { isWebRenewalEnv } from "../../config";

const TitleUpdater = () => {
  useEffect(() => {
    if (isWebRenewalEnv) {
      document.title = "KYC Update";
    } else {
      document.title = "Brokerage";
    }
  }, []);
  return null;
};

export default TitleUpdater;
